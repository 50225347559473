<template>
  <component :is="getLayout">
    <router-view :key="$route.fullPath" />
    <!-- <modals /> -->
    <ModalContainer />
  </component>
</template>
<!--TODO: Add language support-->
<script>
import AuthLayout from "@/layouts/AuthLayout";
import BaseLayout from "@/layouts/BaseLayout";
import DashboardLayout from "@/layouts/DashboardLayout";
import PaymentLayout from "@/layouts/PaymentLayout";
import Modals from "@/components/Pages/Base/Modals";
import ModalContainer from "@/components/Modals/ModalContainer";
import {useI18n} from "vue-i18n";
import i18n from "@/i18n";

export default {
  name: "App",
  components: {
    Modals,
    AuthLayout,
    BaseLayout,
    DashboardLayout,
    PaymentLayout,
    ModalContainer,
  },
  data() {
    return {
      i18n: i18n,
    }
  },
  computed: {
    getLayout() {
      return this.$route.meta.layout || "MainLayout";
    },
  },
  mounted() {
      const storedLang = window.localStorage.getItem('lang') || 'tr'
    this.i18n.global.locale = storedLang
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
@import "~@vueform/multiselect/themes/default.css";
#multiselect {
  .multiselect-single-label,
  .multiselect-placeholder,
  .multiselect-search input,
  .multiselect-options .multiselect-option span {
    font-size: 16px;
    line-height: 34px;
  }
  .multiselect-input {
    height: 100%;
    background: #fbfbfb;
    border: 1px solid #EFF0F2;
    backdrop-filter: blur(6px);
  }
  .multiselect-clear {
    background: #fbfbfb;
  }
  .multiselect-options {
    width: 100%;
    overflow: unset;
    &.is-pointed {
      background-color: red !important;
    }
  }
}
</style>
