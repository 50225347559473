<template>
  <el-modal
    :btnLabel="$t('dashboard-start-spring')"
    modal-wrapper-class="sacrifice-modal sm"
  >
    <template v-slot:body>
      <ul class="nav nav-pills radio-buttons w-100" role="tablist">
        <li class="nav-item flex-grow-1" role="presentation">
          <button
          @click="() => (selectedValue = false)"
            class="nav-link active custom-bt"
            id="button-5"
            data-bs-toggle="pill"
            data-bs-target="#tab-5"
            role="tab"
            type="button"
            aria-controls="tab-5"
            aria-selected="true"
          >
            Kendi Adıma
          </button>
        </li>
        <li class="nav-item flex-grow-1" role="presentation">
          <button
          @click="() => (selectedValue = true)"
            class="nav-link custom-bt"
            id="button-6"
            data-bs-toggle="pill"
            data-bs-target="#tab-6"
            role="tab"
            type="button"
            aria-controls="tab-5"
            aria-selected="true"
          >
            Başkası Adına
          </button>
        </li>
      </ul>
      <div class="content-wrapper">
        <div class="tab-content" id="content">
          <div
            class="tab-pane fade active show"
            id="tab-5"
            role="tabpanel"
            aria-labelledby="button-standard-tab"
          >
            <div class="inputs-wrapper">
              <multi-select2
              v-if="multiple"
                :key="shareRefresher"
                placeholder="Hisse Sayısı"
                :options="options2"
                @select="cathcShareVal"
              />
              <div class="terminate-project w-100">
                <p class="btn-text gray mb-0 medium">Tutar</p>
                <p class="btn-text dark mb-0 text-left bold">{{ amount }}₺</p>
              </div>
              <input-wrapper
                type="Text"
                v-model="name"
                placeholder="Isim Ekle"
              />
              <input-wrapper
                type="numeric"
                v-model="phone"
                placeholder="Telefon Ekle"
              />
              <button-el
                btn-class="bt-bg-primary w-100"
                label="Destek Ol"
                @click="addToBasket"
              />
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="tab-6"
            role="tabpanel"
            aria-labelledby="button-standard-tab"
          >
            <div class="inputs-wrapper">
              <multi-select2
              v-if="multiple"
                :key="shareRefresher"
                placeholder="Hisse Sayısı"
                :options="options"
                @select="cathcShareVal"
              />
              <div class="terminate-project w-100">
                <p class="btn-text gray mb-0 medium">Tutar</p>
                <p class="btn-text dark mb-0 text-left bold">{{ amount }}₺</p>
              </div>
              <div class="input-wrapper">
                <input-wrapper
                  v-model="name"
                  type="Text"
                  placeholder="Isim Ekle"
                />
                <input-wrapper
                  v-model="phone"
                  type="numeric"
                  placeholder="Telefon Ekle"
                />
                <checkbox
                        v-model="isApproved"
                        v-show="selectedValue"
                        :id="51243"
                      >
                        <temlplate> Vekaletimi verdim. </temlplate>
                      </checkbox>
                <button-el
                  btn-class="bt-bg-primary w-100"
                  label="Destek Ol"
                  @click="addToBasket"
                  :isDisabled="
                          !(
                            amount > 0 &&
                            (selectedValue === false ||
                              (selectedValue === true && isApproved))
                          )
                        "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </el-modal>
</template>

<script lang="ts">
import { onMounted, ref } from "vue";
import ElModal from "@/components/UI/Modal.vue";
import InputWrapper from "@/components/UI/InputWrapper.vue";
import ButtonEl from "@/components/UI/ButtonEl.vue";
import Checkbox from "@/components/UI/Checkbox.vue";
import { useRouter } from "vue-router";

import { useStore } from "vuex";
import { addBasket } from "@/helpers/BasketActions";
import PriceInputs from "@/components/Pages/Base/components/PriceInputs.vue";
import Chart from "@/components/UI/Chart.vue";
import { modalStore } from "@/store/modules/modals";
import { userStore } from "@/store/modules/UserStore";
export default {
  components: {
    Chart,
    PriceInputs,
    ElModal,
    ButtonEl,
    InputWrapper,
    Checkbox,
  },
  data() {
    return {
      selectedValue: false,
      isApproved: false,
    };
  },
  setup() {
    const store = modalStore;
    const storeUser = userStore;
    const data = ref(store.getters.getActiveModalData);
    const multiple = data.value.multiple;
    const router = useRouter();
    const amount = ref(data.value.cost);
    const name = ref();
    const phone = ref();
    const options = ref<Array<any>>([]);
    const options2 = ref<Array<any>>([]);
    const shareVal = ref();
    const shareRefresher = ref();
    const shareRefresher2 = ref();

    onMounted(async () => {
      for (let i = 1; i < 12; i++) {
        options.value.push({
          label: `${i} Hisse`,
          value: i,
        });
      }
      for (let i = 2; i < 12; i++) {
        options2.value.push({
          label: `${i} Hisse`,
          value: i,
        });
      }
    });
    const cathcShareVal = (e: any) => {
      shareVal.value = e;
      amount.value = e * data.value.cost;
    };
    const addToBasket = async () => {
      const userId = storeUser.getters.getUser?.id;
      await addBasket(
        data.value.id,
        data.value.type,
        amount.value,
        userId,
        name.value,
        phone.value,
        shareVal.value ? shareVal.value : null
      );
      name.value = "";
      amount.value = "";
      phone.value = "";
      shareRefresher.value = "";
      shareRefresher2.value = "";
      store.commit("closeModal", "codeInputsModal");
    };
    return {
      addToBasket,
      name,
      phone,
      amount,
      cathcShareVal,
      options,
      options2,
      shareRefresher,
      shareRefresher2,
      multiple,
    };
  },
};
</script>

<style lang="scss">
.inputs-wrapper {
  display: flex !important;
  flex-direction: column;
  gap: 18px;

  .input-wrapper {
    display: flex !important;
    flex-direction: column;
    gap: 18px;
  }

  .multiselect-input {
    height: 58px !important;
  }
}
</style>
